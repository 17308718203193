<template>
  <!-- 白名单 -->
  <white-list
    :textLeft="'本机手表卡号：' + sim"
    :background="tool.getThemeList(theme)"
    :showIcon="true"
  >
  </white-list>
</template>

<script>
// 需要哪些模块按需引入
import { toRefs, reactive, onMounted, getCurrentInstance } from "vue";
import { removeStore } from "@/utils/store";
export default {
  setup() {
    const state = reactive({
      sim: "", //sim卡号
      theme: "" //主题名称
    });
    // 这里的ctx  类似于vue2的ctx
    const { proxy: ctx } = getCurrentInstance();
    const { $router: router, $tool: tool, $store: store } = ctx;
    onMounted(() => {
      //获取主题名称
      state.theme = store.state.common.theme;
      //获取用户信息
      const info = store.state.user.userInfo;
      //获取sim卡号
      state.sim = info.sim || "";
      if (!state.sim) {
        removeStore({ name: "loginAccessToken", type: "session" });
        router.push({
          name: "card-query"
        });
        return;
      }
    });
    return {
      ...toRefs(state),
      tool
    };
  }
};
</script>

<style lang="scss" scoped></style>
